import React, { useEffect, useState, useRef } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Widget from './components/Widget/Widget';
import WidgetWithBackground from './components/Widget/WidgetWithBackground';
import Dapp from './components/Dapp/Dapp';


function getQueryVariable(variable)
{
  var query = window.location.search.substring(1);
  console.log(query)//"app=article&act=news_content&aid=160990"
  var vars = query.split("&");
  console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
  for (var i=0;i<vars.length;i++) {
              var pair = vars[i].split("=");
              console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
  if(pair[0] == variable){return pair[1];}
  }
  return(false);
}

function App() {

  if (getQueryVariable("widget") == "clear") {
    return (
      <Widget />
    )
  }

  if (getQueryVariable("widget") == "withbackground") {
    return (
      <WidgetWithBackground />
    )
  }

  return (
      <BrowserRouter>
        <Switch>
          <Route path="/widget/withbackground/">
            <WidgetWithBackground />
          </Route>
          <Route path="/:widget">
            <Widget />
          </Route>
          <Route path="/">
            <Dapp />
          </Route>
        </Switch>
      </BrowserRouter>
  );
}

export default App;
